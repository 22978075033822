import * as React from "react"

import Seo from "../components/Seo"
import Nav from "../components/Nav"

const SubscribePage = () => (
  <>
    <Seo title="Home" />
    <Nav />
    <h1>Subscribe</h1>
    <form>
      <input type="text"></input>
      <input type="submit" value="send"></input>
    </form>
  </>
)

export default SubscribePage